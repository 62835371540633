import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface Reservation {
  id: string;
  type: string;
  attributes: {
    reservation_date: string;
    drop_off_time: string;  
    childrens: Array<{
      full_name: string;
      image: string;         
      age: number;
      color: string;         
    }>;
    care_taker: CareTaker | null;
  };
}

interface CareTaker {
  full_name: string;
  image: string | null; 
}

interface Location {
  id: number;
  latitude: number | null;
  longitude: number | null;
  van_id: number | null;
  address: string;
  title: string;
  duration_id: number;
}

interface ReservationsResponse {
  upcoming: {
    data: Reservation[];
  };
  ongoing_reservations: {
    data: Reservation[];
  };
  completed: {
    data: Reservation[];
  };
};
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  reservationData : ReservationsResponse|null;
  locations : Location[] |null;
  selectedLocation : number|undefined|null|string;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiLocationsCallId:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      reservationData:null,
      locations: null, 
      selectedLocation: localStorage.getItem('selectedLocation')
    };
    this.updateLocationState = this.updateLocationState.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start 
    this.getLocatations();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const token = localStorage.getItem('authToken')
    if(token){
      this.setState({token: token})
    }
    else {
      this.props.navigation.navigate('EmailAccountLoginBlock')  
    }
    const webHeader = {token:token};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.dashboardGetUrl}?location_id=${this.state.selectedLocation}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webApiRequestCallId != null) {
        switch (webApiRequestCallId) {
          case this.apiDashboardItemCallId:
            if (webResponseJson !== undefined && !webResponseJson.errors) {
              this.setState({reservationData:webResponseJson});
            }
            else{
              this.props.navigation.navigate("EmailAccountLoginBlock")
            }
            break;
          case this.apiLocationsCallId:
            if (webResponseJson !== undefined && !webResponseJson.errors) {
              this.setState({locations:webResponseJson.locations});
              localStorage.setItem('locations',JSON.stringify(webResponseJson.locations))
            }
            else{
              this.props.navigation.navigate("EmailAccountLoginBlock")
            }
            break;
          
        }
      }
        
    }

    // Customizable Area End
  }
  // Customizable Area Start
  formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12 || 12}:${minutes} ${suffix}`;
    const day = date.getDate();
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const suffixIndex = (day % 10 <= 3 && day % 100 > 20) ? day % 10 : 0;
    const dayWithSuffix = `${day}${suffixes[suffixIndex]}`;
    return `${formattedDate.replace(day.toString(), dayWithSuffix)} ${formattedTime}`;
  };

  getLocatations(): boolean {
    const token = localStorage.getItem('authToken')
    if(token){
      this.setState({token: token})
    }
    else {
      this.props.navigation.navigate('EmailAccountLoginBlock')  
    }
    const webHeader = {token:token};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLocationsCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.locationListGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  updateLocationState(id:any):void{
    this.setState({selectedLocation:id}, ()=>{
      this.getDashboardData();
    })
  }
  // Customizable Area End
}

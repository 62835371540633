Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "account_block/accounts/facility_manager_login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Staff ID is not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Welcome";
exports.forgotPassword = "Forgot password?";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End

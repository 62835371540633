import React from "react";
import { Box, Typography, Avatar } from "@mui/material";

interface ProfileCardProps {
  kidImg: string;
  kidName: string;
  parentImg?: string;
  parentName?: string;
  sideColor?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  kidImg,
  kidName,
  parentImg,
  parentName,
  sideColor,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        padding: "8px",
        position: "relative",
        backgroundColor: "#fff",
        maxWidth:'350px'
      }}
    >
      {/* Profile Images */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Avatar src={kidImg} alt={kidName} sx={{ width: 40, height: 40 }} />
        {parentImg && (
          <Avatar
            src={parentImg}
            alt={parentName || "Parent"}
            sx={{ width: 40, height: 40, marginLeft: "-20px", zIndex: 1 }}
          />
        )}
      </Box>
      {/* Names */}
      <Box sx={{ marginLeft: "8px", flexGrow: 1 }}>
       
        {parentName ? (
          <>
           <Typography variant="body2" sx={{ color: "#374151" }}>
            Child: {kidName}
          </Typography>
          <Typography variant="body2" sx={{ color: "#6b7280" }}>
            Caretaker: {parentName}
          </Typography>
          </>
        ): <Typography variant="body2" sx={{ color: "#374151" }}>
        {kidName}
      </Typography>}
      </Box>
      {/* Sidebar color */}
      {sideColor && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            width: "9px",
            backgroundColor: sideColor,
            borderRadius: "0px 8px 8px 0px",
          }}
        />
      )}
    </Box>
  );
};

export default ProfileCard;


import React from "react";
// Customizable Area Start
import { Box, Typography, CircularProgress ,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, IconButton } from "@mui/material";
import ProfileCard from "../../../components/src/ProfileCard";
import { ArrowForwardIos, Email } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

import { SideBar } from "../../../components/src/SideBar";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class DashbordCaretakerScreen extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box width={'100vw'} height={'100vh'} >
        <SideBar SideBarContent={<></> } 
         
          locations={this.state.locations ||[]}
          HeaderTitel={<Typography variant="h6" fontWeight="semibold" fontFamily={"Inter"} fontSize={'28px'}
          >
          {this.formatDate(Date.now())}
        </Typography>} 
        NavigationFunction={this.props.navigation.navigation}
        updateLocationState={this.updateLocationState}
        >
         <div style={{ padding: "20px" }}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: "bold", marginBottom: "16px" }}>
          Caretaker - Adults
        </Typography>
                  
        
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{marginBottom:'30px'}}>
              <TableRow style={{ backgroundColor: "#f8d7da"  }}>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                  Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                  Email
                </TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                  Phone Number
                </TableCell>
                <TableCell style={{ fontWeight: "bold", fontSize: 16 }}>
                  Member Since
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[ {
        name: "Amy Roberts",
        email: "amy.roberts@gmail.com",
        phone: "551-235-5678",
        memberSince: "January 14, 2024",
        avatar: "https://via.placeholder.com/40", // Replace with actual image URL
      },{
        name: "Amy Roberts",
        email: "amy.roberts@gmail.com",
        phone: "551-235-5678",
        memberSince: "January 14, 2024",
        avatar: "https://via.placeholder.com/40", // Replace with actual image URL
      }].map((caretaker, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "#fde2e4" : "#ffffff",
                  }}
                >
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={caretaker.avatar}
                        alt={caretaker.name}
                        style={{ marginRight: "10px" }}
                      />
                      {caretaker.name}
                    </div>
                  </TableCell>
                  <TableCell>{caretaker.email}</TableCell>
                  <TableCell>{caretaker.phone}</TableCell>
                  <TableCell>{caretaker.memberSince}</TableCell>
                  <TableCell>
                    <IconButton>
                      <ArrowForwardIos fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      
      </div>
        
       </SideBar>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  Colcont:{flex:1,display:'flex', flexDirection:'column', background:'#ffff'},
  ColTitelGry :{
    fontSize:'18px',
    color:'3D475C',
    background:'#ffff'
  },
  
  
};
// Customizable Area End
